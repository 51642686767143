/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const SEO = ({
  title,
  description,
  meta_title,
  meta_description,
  og_description,
  og_title,
  twitter_description,
  twitter_title,
  type = "website",
  extra = [],
}) => {
  const url = typeof window !== "undefined" && window.location.href;

  return (
    <Helmet
      title={`${meta_title || title} - Lean With Lilly`}
      meta={[
        {
          name: "description",
          content: meta_description || description,
        },
        {
          property: "og:site_name",
          content: "Lean With Lilly",
        },
        {
          property: "og:url",
          content: url,
        },
        {
          property: "og:title",
          content: og_title || title,
        },
        {
          property: "og:type",
          content: type,
        },
        {
          property: "og:description",
          content: og_description || description,
        },
        {
          name: "twitter:site",
          content: "@lillysabri?lang=en",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: twitter_title || title,
        },
        {
          name: "twitter:description",
          content: twitter_description || description,
        },
        ...extra,
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
