import React from "react";
import SEO from "../components/SEO";
import { Link } from "gatsby";

import { Box } from "../elements/Box";
import { Heading } from "../elements/Heading";
import { Flex } from "../elements/Flex";
import { Text } from "../elements/Text";
import { Span } from "../elements/Span";
import { Grid } from "../elements/Grid";
import banner from "../images/banners/challengeBanner.mp4";
import { Image } from "../elements/Image";
import challengeImgBanner from "../images/banners/challengeImageBanner.jpg";
import img1 from "../images/challenge/week1.png";
import img2 from "../images/challenge/week2.png";
import img3 from "../images/challenge/week3.png";
import img4 from "../images/challenge/week4.png";
import img5 from "../images/challenge/week5.png";
import img6 from "../images/challenge/week6.png";
import badge from "../images/enrolment-badge.svg";

const weeks = [
  "Strong vs Fat Burn",
  "Pilates x Kickbox",
  "Your Strongest Self",
  "Full Body Shred & Insane Abs",
  "Lilly Sabri Special Surprise",
  "Abtasticly Strong & Shredded ",
];

const prizes = [
  {
    txt: "iPhone 13",
    img: img1,
  },
  {
    txt: "LEAN Mat of your choosing and LEAN ultimate bundle, kit and supps ",
    img: img2,
  },
  {
    txt: "MacBook Air",
    img: img3,
  },
  {
    txt: "LEAN Supplements for a year, including being sent any new products as soon as they’re available  ",
    img: img4,
  },
  {
    txt: "$500 Cash & Zoom Coffee + Chat with Lilly",
    img: img5,
  },
  {
    txt: "$2,000 Cash",
    img: img6,
  },
];

const ChallengePage = () => {
  return (
    <Box>
      <div
        style={{
          position: "fixed",
          height: "25vw",

          fontWeight: "bold",
          textAlign: "center",
          color: "#fff",
          fontSize: "1.3rem",
          zIndex: 9,
          width: "25vw",

          top: "0",
          left: "0",
        }}
      >
        <img src={badge} />
      </div>

      <SEO title="Challenge" />

      <div style={{ position: "relative", height: "auto" }}>
        <video autoPlay playsInline loop muted style={{ display: "block", width: "100%" }}>
          <source type="video/mp4" src={banner} />
        </video>
      </div>

      <Box px={2} py={[4, 4, 5]} maxWidth="900px" margin="0 auto" textAlign="center">
        <Heading mb={4} fontSize={[24, 34, 44]}>
          Get LEAN & Strong Transformation Challenge
        </Heading>

        <Text mb={3}>
          Familia, the biggest challenge EVER has arrived at LEAN! The Get LEAN & Strong Transformation Guide is a 6
          week challenge designed to get you results whilst making you feel your most strong, both mentally and
          physically.
        </Text>
        <Text>
          The 6 week challenge is made up of 6 weekly guides all having a different focus to them but with a big mix of
          training styles to keep it exciting and bring the fiyaaaah. So what’s coming your way?
        </Text>
        <br />
        <Text>
          *Disclaimer: You must be a LEAN Premium Member to sign up to the challenge via the LEAN App - you can join{" "}
          <Link to="/join" style={{ color: "blue" }}>
            here.
          </Link>{" "}
        </Text>
      </Box>

      <Box bg="darkTeal" px={2} py={[4, 4, 5]} color="white">
        <Heading mb={4} textAlign="center" fontSize={[24, 34]}>
          CHALLENGE WEEKLY OVERVIEW
        </Heading>

        <Flex
          maxWidth="1100px"
          margin="0 auto"
          alignItems={["center", null, "flex-start"]}
          flexDirection={["column", null, null, "row"]}
          justifyContent="space-between"
        >
          {weeks.map((item, index) => (
            <Box key={index} maxWidth={[null, null, "175px"]} textAlign="center" mb={[3, 3, 3, 0]}>
              <Heading fontSize={18} mb={2}>
                Week {index + 1}
              </Heading>

              <Text>{item}</Text>
            </Box>
          ))}
        </Flex>
      </Box>

      <Image src={challengeImgBanner} />

      <Box px={2} py={[4, 4, 5]} maxWidth="900px" margin="0 auto" textAlign="center">
        <Text mb={3}>
          This is an enrollment only challenge with <b>over $5000 worth of prizes</b> up for grabs with prizes every
          single week! To take part you’ll need to be enrolled, the workouts are exclusive to those who have enrolled in
          the challenge.
        </Text>
        <Text>
          The challenge begins at <Span fontWeight="700">8am GMT Monday 3rd January,</Span> make sure your app is the
          most up to date version or you’re restarted your app if the new guide isn’t showing for you!
        </Text>

        <br />
        <Text>
          *Disclaimer: You must be a LEAN Premium Member to sign up to the challenge via the LEAN App - you can join{" "}
          <Link to="/join" style={{ color: "blue" }}>
            here.
          </Link>{" "}
        </Text>
      </Box>

      <Box bg="lilac" px={2} py={[4, 4, 5]} color="white">
        <Heading mb={3} textAlign="center" fontSize={[24, 34]}>
          WHAT ARE THE PRIZES?
        </Heading>

        <Text margin="0 auto" mb={4} maxWidth="500px" textAlign="center">
          Every week one lucky winner will be selected at random to win some incredible goodies. Up for grabs we’ve got:
        </Text>

        <Flex
          alignItems={["center", null, "flex-start"]}
          maxWidth="1100px"
          margin="0 auto"
          flexDirection={["column", null, null, "row"]}
          justifyContent="space-between"
        >
          {prizes.map((item, index) => (
            <Box maxWidth={[null, null, "175px"]} key={index} textAlign="center" mb={[3, 3, 3, 0]}>
              <Heading fontSize={18} mb={2}>
                Week {index + 1}
              </Heading>

              <img src={item.img} style={{ width: 100, height: "auto" }} />

              <Text>{item.txt}</Text>
            </Box>
          ))}
        </Flex>
      </Box>

      <Grid
        display={["block", "block", "grid"]}
        gridTemplateColumns="1fr 1fr"
        gridGap={4}
        px={2}
        py={[4, 4, 5]}
        maxWidth="1100px"
        margin="0 auto"
        textAlign="center"
      >
        <Box mb={[3, 3, 0]}>
          <Heading mb={3} textAlign="center" fontSize={[24, 30]}>
            HOW DO I WIN THE WEEKLY PRIZES?
          </Heading>

          <Text mb={3}>
            To win the weekly prize all you’ll need to do is complete all days of the weekly guide, share your guide
            completed screen on your stories and tag <b>@leanwithlilly</b> to be entered!
          </Text>
        </Box>

        <Box>
          <Heading mb={3} textAlign="center" fontSize={[24, 30]}>
            HOW DO I WIN THE OVERALL PRIZES?
          </Heading>

          <Text mb={3}>
            To win the overall prize all you’ll need to have completed all 6 weeks of the challenge guides, share your
            guide completed screens on your stories and tag <b>@leanwithlilly</b> to be entered!
          </Text>
        </Box>
      </Grid>

      <Box textAlign="center" pb={[4, 4, 5]}>
        <br />
        <Text>
          *Disclaimer: You must be a LEAN Premium Member to sign up to the challenge via the LEAN App - you can join{" "}
          <Link to="/join" style={{ color: "blue" }}>
            here.
          </Link>{" "}
        </Text>
      </Box>
    </Box>
  );
};

export default ChallengePage;
